import Media from './Media'
import Updatable from './Updatable'

export default class Report {

    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null
        object.state ? this.state = object.state : this.state = null
        object.commentShipper ? this.commentShipper = object.commentShipper : this.commentShipper = null
        object.commentConsignee ? this.commentConsignee = object.commentConsignee : this.commentConsignee = null
        if(object.medias){
            var medias = []
            object.medias.forEach((media) => {
                medias.push(new Media(media))
            })
            this.medias = medias
        }
        object.updatable ? this.updatable = new Updatable(object.updatable) : this.updatable = null
    }

}