import Update from './Update'

export default class Updatable {


    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null

        object.createdAt ? this.createdAt = new Date(object.createdAt) : this.createdAt = null
        object.createdBy ? this.createdBy = object.createdBy : this.createdBy = null

        if(object.updates){
            var updates = []
            object.updates.forEach((update) => {
                updates.push(new Update(update))
            })
            this.updates = updates
        }
        object.lastUpdatedAt ? this.lastUpdatedAt = new Date(object.lastUpdatedAt) : this.lastUpdatedAt = null

        object.deletedBy ? this.deletedBy = object.deletedBy : this.deletedBy = null
        object.deletedAt ? this.deletedAt = new Date(object.deletedAt) : this.deletedAt = null

    }

}