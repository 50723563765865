export default class Media {

    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.url ? this.url = object.url : this.url = null
        object.type ? this.type = object.type : this.type = null
    }

    download(){
        const link = document.createElement('a')
        link.href = this.url
        link.download = this.url+"."+this.getUrlExtension()
        link.click()
        URL.revokeObjectURL(link.href)
    }

    getUrlExtension() {
        return this.url.split(/[#?]/)[0].split('.').pop().trim();
    }

}