import Traqr from './Traqr'
import Company from './Company'

export default class Reference {


    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null
        object.companyOwner ? this.companyOwner = new Company(object.companyOwner) : this.companyOwner = null
        object.traqr ? this.traqr = new Traqr(object.traqr) : this.traqr = null
        object.reference ? this.reference = object.reference : this.reference = null
        object.type ? this.type = object.type : this.type = null
        object.shareable ? this.shareable = object.shareable : this.shareable = null
    }

}