import Address from './Address'
import Updatable from './Updatable'
import Connection from './Connection'

export default class Scan {


    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null
        if(object.scans){
            var scans = []
            object.scans.forEach((scan) => {
                scans.push(new Scan(scan))
            })
            this.scans = scans
            this.sortScansByDate()
        }
        object.address ? this.address = new Address(object.address) : this.address = null
        
        object.connection ? this.connection = new Connection(object.connection) : this.connection = null
        
        object.updatable ? this.updatable = new Updatable(object.updatable) : this.updatable = null
    }

    sortScansByDate(){
        if(this.scans.length > 0){
            this.scans = this.scans.sort((a,b) => {
               return new Date(b.scannedAt).getTime() - new Date(a.scannedAt).getTime()
            })
        }
    }

}