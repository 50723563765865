import Updatable from './Updatable'
import Connection from './Connection'

export default class Visitor {


    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null
        object.trustLevel ? this.trustLevel = object.trustLevel : this.trustLevel = null
        object.email ? this.email = object.email : this.email = null
        object.companyName ? this.companyName = object.companyName : this.companyName = null
        object.firstName ? this.firstName = object.firstName : this.firstName = null
        object.lastName ? this.lastName = object.lastName : this.lastName = null
        object.phone ? this.phone = object.phone : this.phone = null

        if(object.connections){
            var connections = []
            object.connections.forEach((connection) => {
                connections.push(new Connection(connection))
            })
            this.connections = connections
        }

        object.updatable ? this.updatable = new Updatable(object.updatable) : this.updatable = null

    }

}