import Report from './Report'
import Address from './Address'
import ScanList from './ScanList'
import CountryUtils from '../Country'
import Updatable from './Updatable'


export default class Scan {

    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null
        
        //resource
        object.resource ? this.resource = object.resource : this.resource = null
        object.resourceIri ? this.resourceIri = object.resourceIri : this.resourceIri = null
        
        object.state ? this.state = object.state : this.state = null
        object.report ? this.report = new Report(object.report) : this.report = null
        object.address ? this.address = new Address(object.address) : this.address = null
        object.scanList ? this.scanList = new ScanList(object.scanList) : this.scanList = null

        object.updatable ? this.updatable = new Updatable(object.updatable) : this.updatable = null

    }

    getLastScanTime(){
    return new Date(this.updatable.lastUpdatedAt).toLocaleTimeString()
    }
    getLastScanDate(){
        return new Date(this.updatable.lastUpdatedAt).toLocaleDateString()
    }
    hasAddress(){
        return this.address ? true : false
    }
    getAddress(){
        return this.address ? this.address.addressLine1 : null
    }
    getCity(){
        return this.address ? this.address.city : null
    }
    getCountry(){
        return this.address ? CountryUtils.getCountryCode(this.address.country) : null
    }
    getScannedEntityType(){

        if(this.resourceIri.indexOf('handling_units') > 0 ){
            return 'handlingUnit'
        }
        return 'traqr'
    }
    getScannedEntity(){
        return this.getScannedEntityType() === 'handlingUnit' ? 'Étiquette UM' : 'Bon de Livraison'
    }

}