<template>
    <div>
        <div 
        class="fixed top-0 left-0 z-20 h-full w-full bg-gray-500 opacity-50"
        @click="$emit('closeModal')"
        >
        </div>
        <div 
        class="fixed top-[120px] left-[50%] -translate-x-2/4 z-50 bg-gray-50 rounded-modal shadow-xl flex flex-col justify-between" 
        :class="!isMobile() ? 'w-50%' : 'w-85%'"
        >
            <div class="flex flex-row justify-between items-center p-4 border-b">
                <span class="text-xl font-bold">{{ title }}</span>
                <component 
                :is="heroIcons['XMarkIcon']" 
                fill="currentColor" 
                class="stroke-1 w-6 h-6"
                @click="$emit('closeModal')"
                />
            </div>
            <div class="flex flex-row justify-center items-center p-4">
                <slot name="body">
                    This is the default body!
                </slot>
            </div>
            <div v-if="confirmButtonLabel !== 'none'" class="flex flex-row justify-end items-center p-4 border-t">
                <ButtonVue
                class="mr-2"
                :state="'neutral'"
                :height="'medium'"
                :filling="'outlined'"
                :label="'ANNULER'"
                @click="$emit('closeModal')"
                />
                <ButtonVue
                :state="'default'"
                :height="'medium'"
                :filling="'filled'"
                :label="confirmButtonLabel"
                @click="$emit('confirm')"
                />
            </div>
        </div>
    </div>
  </template>
  
  <script>
  
  import * as heroIcons from '@heroicons/vue/24/outline'
  import ButtonVue from "../elements/Button.vue"
  
  export default {
    name: 'ModalVue',
    components: {
      ButtonVue,
    },
    props:{
        title:{
            type: String,
            default: 'Title'
        },
        confirmButtonLabel:{
            type: String,
            default: 'CONFIRMER'
        },
    },
    data() {
        return {
            heroIcons: heroIcons,
        }
    },
    methods:{
      isMobile() {
          return this.$device === 'mobile' ||  this.$orientation === 'portrait'
      },
    },
    watch:{
      title:{
          handler(){},
          deep: true,
      },
      confirmButtonLabel:{
          handler(){},
          deep: true,
      },

    }
  }
  </script>
  
  