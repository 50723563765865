import Address from './Address'
import Visitor from './Visitor'
import ScanList from './ScanList'


export default class Connection {


    constructor(object = {}){
        this.build(object)
    }
 
    build(object){
        object.id ? this.id = object.id : this.id = null

        object.fingerprint ? this.fingerprint = object.fingerprint : this.fingerprint = null

        object.ip ? this.ip = object.ip : this.ip = null

        object.zipCodeForIp ? this.zipCodeForIp = object.zipCodeForIp : this.zipCodeForIp = null

        object.connectionStartAt ? this.connectionStartAt = new Date(object.connectionStartAt) : this.connectionStartAt = null

        object.connectionEndAt ? this.connectionEndAt = new Date(object.connectionEndAt) : this.connectionEndAt = null

        object.address ? this.address = new Address(object.address) : this.address = null

        object.visitor ? this.visitor = new Visitor(object.visitor) : this.visitor = null
        
        if(object.scanLists){
            var scanLists = []
            object.scanLists.forEach((scanList) => {
                scanLists.push(new ScanList(scanList))
            })
            this.scanLists = scanLists
        }

    }

    save(){

    }


}