import Company from './Company'
import Updatable from './Updatable'

export default class User {


    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null
        object.email ? this.email = object.email : this.email = null
        object.roles ? this.roles = object.roles : this.roles = null
        object.firstName ? this.firstName = object.firstName : this.firstName = null
        object.lastName ? this.lastName = object.lastName : this.lastName = null
        object.phone ? this.phone = object.phone : this.phone = null
        object.companyOwner ? this.companyOwner = new Company(object.companyOwner) : this.companyOwner = null
        object.updatable ? this.updatable = new Updatable(object.updatable) : this.updatable = null
    }

}