import Traqr from './Traqr'
import Updatable from './Updatable'
import Scanner from './Scanner'
import Scan from './Scan'


export default class HandlingUnit {


    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null
        object.scanner ? this.scanner = new Scanner(object.scanner) : this.scanner = null
        if(object.scans){
            var scans = []
            object.scans.forEach((scan) => {
                scans.push(new Scan(scan))
            })
            this.scans = scans
            this.sortScansByDate()
        }
        object.traqr ? this.traqr = new Traqr(object.traqr) : this.traqr = null
        object.reference ? this.reference = object.reference : this.reference = null
        object.height ? this.height = object.height : this.height = null
        object.width ? this.width = object.width : this.width = null
        object.length ? this.length = object.length : this.length = null
        object.weight ? this.weight = object.weight : this.weight = null
        object.updatable ? this.updatable = new Updatable(object.updatable) : this.updatable = null
    }

    sortScansByDate(){
        if(this.scans.length > 0){
            this.scans = this.scans.sort((a,b) => {
               return new Date(b.scannedAt).getTime() - new Date(a.scannedAt).getTime()
            })
        }
    }

}