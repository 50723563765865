import axios from "axios"

import Updatable from './Updatable'
import Company from './Company'
import Destination from './Destination'


export default class Contact {


    constructor(object = {}){
        this.build(object)
    }
 
    build(object){
        object.id ? this.id = object.id : this.id = null

        object.firstName ? this.firstName = object.firstName : this.firstName = ''

        object.lastName ? this.lastName = object.lastName : this.lastName = ''

        object.companyName ? this.companyName = object.companyName : this.companyName = ''

        object.email ? this.email = object.email : this.email = ''

        object.phone ? this.phone = object.phone : this.phone = ''

        object.rawContact ? this.rawContact = object.rawContact : this.rawContact = ''
        
        if(object.destinations){
            var destinations = []
            object.destinations.forEach((destination) => {
                destinations.push(new Destination(destination))
            })
            this.destinations = destinations
        }

        object.companyOwner ? this.companyOwner = new Company(object.companyOwner) : this.companyOwner = null

        object.updatable ? this.updatable = new Updatable(object.updatable) : this.updatable = null

    }

    uri(){
        return process.env.VUE_APP_API_PREFIX+'/contacts/'+this.id
    }

    toPostJson(){
        return {
            "firstName": this.firstName,
            "lastName": this.lastName,
            "companyName": this.companyName,
            "email": this.email,
            "phone": this.phone,
          }
    }

    toPatchJson(){
        return {
            "firstName": this.firstName,
            "lastName": this.lastName,
            "companyName": this.companyName,
            "email": this.email,
            "phone": this.phone,
          }
    }

    async update(){
        await axios
        .patch(process.env.VUE_APP_API_BASE_PATH+'/contacts/'+this.id, this.toPatchJson(),
        {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
            withCredentials: true 
        })
        .then(response => this.build(response.data))
        return this
    }

    async save() {
        await axios
        .post(process.env.VUE_APP_API_BASE_PATH+'/contacts', this.toPostJson(),
        {
            headers: {
                'Content-Type': 'application/ld+json',
            },
            withCredentials: true 
        }).then(response => this.build(response.data))
        return this
    }

    async delete(){
        await axios
        .delete(process.env.VUE_APP_API_BASE_PATH+'/contacts/'+this.id,
        {
            withCredentials: true 
        })
    }

    display(){
        var valren = ''
        valren += this.companyName ? this.companyName : ''
        valren += this.companyName && (this.firstName || this.lastName) ? " - " : ''
        valren += this.firstName ? this.firstName + " " : ''
        valren += this.lastName ? this.lastName  : ''
        return valren
    }

    async searchContact(q){
        var contacts = []
        var query = process.env.VUE_APP_API_BASE_PATH+'/contacts?q='+q
        await axios
            .get(query, {  withCredentials: true })
            .then(response => {
                if(response.status == 200){
                    contacts = response.data['hydra:member']
                }
            })
        return contacts
    }

}