import Updatable from './Updatable'

export default class Update {


    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null
        object.updatedBy ? this.updatedBy = object.updatedBy : this.updatedBy = null
        object.updatedAt ? this.updatedAt = new Date(object.updatedAt) : this.updatedAt = null
        object.modifications ? this.modifications = object.modifications : this.modifications = null
        object.updatable ? this.updatable = new Updatable(object.updatable) : this.updatable = null
    }

}