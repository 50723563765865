
import User from './User'
import Updatable from './Updatable'

export default class Company {


    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null
        object.name ? this.name = object.name : this.name = null
        object.siren ? this.siren = object.siren : this.siren = null
        object.siret ? this.siret = object.siret : this.siret = null
        object.phone ? this.phone = object.phone : this.phone = null
        object.shortName ? this.shortName = object.shortName : this.shortName = null

        if(object.users){
            var users = []
            object.users.forEach((user) => {
                users.push(new User(user))
            })
            this.users = users
        }
        object.updatable ? this.updatable = new Updatable(object.updatable) : this.updatable = null
     }

}