<template lang="html">
    <button class="rounded-button transition-all box-border flex justify-around items-center shadow-sm hover:shadow-lg active:shadow-xs" 
      :class="styling($styles.button.button)"
      :disabled="state === 'disabled' ? 'disabled' : null">
        <component 
        v-if="icon" 
        :is="$heroIcons[icon]" 
        fill="currentColor" 
        class="stroke-1 mx-2"
        :class="styling($styles.button.icon)"  
        />
        <span v-if="label" class="mx-2">
          {{ label }}
        </span>
    </button>
  </template>
  
  <script>
  
  export default  {
    name: 'ButtonVue',
    props: {
      label: {
        type: String,
        default: "Button"
      },
      filling: {
        type: String, 
        validator(value) {
          return ['filled', 'outlined', 'text'].includes(value)
        }, 
        default: 'filled'
      },
      height: {
        type: String, 
        validator(value) {
          return ['large', 'medium', 'small'].includes(value)
        }, 
        default: 'large'
      },
      icon: {
        type: String,
        default: null
      },
      icon_position: {
        type: String, 
        validator(value) {
          return ['left', 'right'].includes(value)
        }, 
        default: 'right'
      },
      state: {
        type: String,
        validator(value) {
          return ['default', 'success', 'danger', 'warning', 'disabled','neutral'].includes(value)
        }, 
        default: 'default'
      },
    },
    watch: {
      label: {
      handler() {},
      deep: true
      },
      filling: {
      handler() {},
      deep: true
      },
      height: {
      handler() {},
      deep: true
      },
      icon: {
      handler() {},
      deep: true
      },
      icon_position: {
      handler() {},
      deep: true
      },
      state: {
      handler() {},
      deep: true
      },
    },
    methods: {
      getHeight(object){
        switch (this.height){
          case 'large':{
            return object.large
          }
          case 'medium':{
            return object.medium
          }
          case 'small':{
            return object.small
          }
          default:{
            return ""
          }
        }
      },
      getFilling(object){
        switch (this.filling){
          case 'filled':{
            switch (this.state){
              case 'default':{
                return object.filled.default
              }
              case 'success':{
                return object.filled.success
              }
              case 'danger':{
                return object.filled.danger
              }
              case 'warning':{
                return object.filled.warning
              }
              case 'disabled':{
                return object.filled.disabled
              }
              default:{
                return object.filled.neutral
              }
            }
          }
          case 'outlined':{
            switch (this.state){
              case 'default':{
                return object.outlined.default
              }
              case 'success':{
                return object.outlined.success
              }
              case 'danger':{
                return object.outlined.danger
              }
              case 'warning':{
                return object.outlined.warning
              }
              case 'disabled':{
                return object.outlined.disabled
              }
              default:{
                return object.outlined.neutral
              }
            }
          }
          case 'text':{
            switch (this.state){
              case 'default':{
                return object.text.default
              }
              case 'success':{
                return object.text.success
              }
              case 'danger':{
                return object.text.danger
              }
              case 'warning':{
                return object.text.warning
              }
              case 'disabled':{
                return object.text.disabled
              }
              default:{
                return object.text.neutral
              }
            }
          }
          default:{
            return object.filled.neutral
          }
        }
      },
      getIconPosition(object){
        switch (this.icon_position){
          case 'left':{
            return object.left
          }
          case 'right':{
            return object.right
          }
          default:{
            return ""
          }
        }
      },
      styling(object){
        var valren = ''
        object.height ? valren += this.getHeight(object.height) +' ' : null
        object.filling ? valren += this.getFilling(object.filling) +' ' : null
        object.icon_position? valren += this.getIconPosition(object.icon_position) +' ' : null
        return valren
      }
    },
  }
    
    
    </script>
    