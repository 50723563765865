import ScanList from './ScanList'

export default class Scanner {


    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null
        object.redirects ? this.redirects = object.redirects : this.redirects = null
        object.resource ? this.resource = object.resource : this.resource = null
        object.scanList ? this.scanList = new ScanList(object.scanList) : this.scanList = null

    }

}