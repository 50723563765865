import axios from 'axios'
import CountryUtils from '../Country'

export default class Address {


    constructor(object = {}){
       this.build(object)
    }

    build(object){
        object.id ? this.id = object.id : this.id = null
        object.addressLine1 ? this.addressLine1 = object.addressLine1 : this.addressLine1 = null
        object.addressLine2 ? this.addressLine2 = object.addressLine2 : this.addressLine2 = null
        object.addressLine3 ? this.addressLine3 = object.addressLine3 : this.addressLine3 = null
        object.city ? this.city = object.city : this.city = null
        object.zipCode ? this.zipcode = object.zipcode : this.zipcode = null
        object.country ? this.country = object.country : this.country = null
        object.longitude ? this.longitude = object.longitude : this.longitude = null
        object.latitude ? this.latitude = object.latitude : this.latitude = null
        this.sessionToken = null
    }

    uri(){
        return process.env.VUE_APP_API_PREFIX+'/addresses/'+this.id
    }

    async searchAddress(){
        var addresses = []
        var query = process.env.VUE_APP_API_BASE_PATH+'/addresses_autocomplete?input='+this.raw
        if(this.sessionToken){
            query = query +'&sessiontoken='+this.sessionToken
        }
        await axios
            .get(query, {  withCredentials: true })
            .then(response => {
                if(response.status == 200){
                    this.sessionToken = response.data.sessiontoken
                    addresses = response.data.predictions
                }
            })
        return addresses
    }

    async setAddress(object) {
        var query = process.env.VUE_APP_API_BASE_PATH+'/address_details?place_id='+object.place_id+'&sessiontoken='+this.sessionToken
        await axios
        .get(query, {  withCredentials: true })
        .then(response => {
            if(response.status == 200){
                this.build(response.data)
                this.sessionToken = null
            }
        })
    }

    display() {
        var valren = ''
        valren += this.addressLine1 ? this.addressLine1  : ''
        valren += this.city ? ", " + this.city : ''
        valren += this.zipcode ? " " + this.zipcode  : ''
        valren += this.country ? ", " + this.country : ''
        return valren
    }

    getAddress(){
        return this.addressLine1 ? this.addressLine1 : null
    }
    getCity(){
        return this.city ? this.city : null
    }
    getCountry(){
        return this.country ? CountryUtils.getCountryCode(this.country) : null
    }

}